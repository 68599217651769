import _mode from "./mode";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var mode = _mode;

function QR8bitByte(data) {
  (this || _global).mode = mode.MODE_8BIT_BYTE;
  (this || _global).data = data;
}

QR8bitByte.prototype = {
  getLength: function (buffer) {
    return (this || _global).data.length;
  },
  write: function (buffer) {
    for (var i = 0; i < (this || _global).data.length; i++) {
      // not JIS ...
      buffer.put((this || _global).data.charCodeAt(i), 8);
    }
  }
};
exports = QR8bitByte;
export default exports;